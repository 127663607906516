import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { JlashServices, AlertService } from './services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: JlashServices, private router: Router, private alert : AlertService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.auth.prevRouter = this.router.url;
        let userData = this.auth.getLoggedUserInfo();
        let path = next.routeConfig.path;
        if (this.auth.isLoggedIn()) {
            if (this.isAuthPage(state)) {
                const page = 'pages/dashboard';
                if (page) {
                    this.router.navigate([page]);
                    return false;
                }
            } else {
                if(state.url.indexOf('pages/user') >= 0 ) {
                    let userInfo = this.auth.getLoggedUserInfo();
                    if(userInfo.role == 'staff') {
                        this.router.navigate(['notfounderror']);
                    } 
                }
            }
            return true;
        } else {
            // not logged in so redirect to login page with the return url and return false
            if (this.isAuthPage(state)) {
                return true;
            }
            this.auth.logout();
            // this.alert.showWarning('wewe', 'Unauthorized Userss! please login again');
            this.router.navigate(['login']);
        }
        return false;
    }

    isAuthPage(state) {
        if (state) {
            if (state.url.indexOf('login') >= 0) {
                return true;
            }
        }
    }


}
