import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JlashServices, JLASHCONSTANT, AlertService } from '../../services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordform: FormGroup;
  loading: boolean;
  resetId: any = '';
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dataService: JlashServices, private alert:AlertService) {
    this.passwordform = this.formBuilder.group({
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    },
      { validator: this.passwordMatchValidator })
  }

  //Check New Password and Confirm Password
  passwordMatchValidator(g: FormGroup) {
    return g.get('newpassword').value === g.get('confirmpassword').value
      ? null : { 'mismatch': true };
  }

  ngOnInit() {
    this.resetId = this.activatedRoute.snapshot.queryParams.keyString ? this.activatedRoute.snapshot.queryParams.keyString : '';
  }

  changePassword(formData) {
    let data = {};
    data['newPassword'] = formData.newpassword;
    this.loading = true;
    data['type'] = 'reset';
    data['token'] = this.resetId;
    this.dataService.addData(JLASHCONSTANT.API_CHANGE_PASSWORD, data).subscribe((data: any) => {
      this.loading = false;
      if (data.status === 1) {
        this.alert.show('Successfully Updated.')
        this.router.navigate(['login']);
      }
    }, err => {
      this.loading = false;
    });
  }

}
