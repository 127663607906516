import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginComponent } from './component/login/login.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';

import { AuthGuard } from './auth.guard'
const routes: Routes = [
	{
		path: 'pages',
		loadChildren: 'app/component/pages/pages.module#PagesModule',
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always'
	}, {
		path: 'login',
		component: LoginComponent
	}, {
		path: 'forget-password',
		component: ForgotPasswordComponent
	}, {
		path: 'reset-password',
		component: ResetPasswordComponent
	}, {
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	}, {
		path: 'notfounderror',
		component: PageNotFoundComponent
	}
];

const config: ExtraOptions = {
	useHash: true,
	onSameUrlNavigation: 'reload'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule]
})
export class AppRoutingModule { }