import { Injectable, EventEmitter, Output} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JLASHCONSTANT } from './constant';
import { CustomDataSource } from './custom.data.service';
import { Http } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from './alert.service'
import * as jwt_decode from 'jwt-decode';



// @Output() drawEvent = new EventEmitter<string>();

// Draw(event) {
//   this.drawEvent.emit(event);
// }


@Injectable()
export class JlashServices {

  public apiURL = environment.apiUrl;

  private reloadAppPage = new BehaviorSubject<any>([]);
  refreahAppPage = this.reloadAppPage.asObservable();
  public prevRouter = '';

  // private searchCustomer = new BehaviorSubject<any>([]);
  // customerDetails = this.searchCustomer.asObservable();

  constructor(private httpClient: HttpClient, private alert: AlertService, private http: Http, private router: Router) { }

  updateDes(value) {
    this.reloadAppPage.next(value);
  }

  // customerUpdation(customerId) {
  //   this.searchCustomer.next(customerId);
  // }

  public getHttpOptions() {
    let localDetails = this.getLoggedUserInfo();
    const userData = localDetails && localDetails['sessionId'] ? localDetails['sessionId'] : null;
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('sessionId', userData ? userData : '')
        .set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token,Authorization')
        .set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
    };
  }

  public getNormalHttpOptions() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token,Authorization')
        .set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
    };
  }

  getLoggedUserInfo() {
    return JSON.parse(localStorage.getItem('jlash-info'));
  }

  addData(url, data) {
    return this.httpClient.post(this.apiURL + url, data, this.getHttpOptions());
  }

  updateData(url, data) {
    return this.httpClient.put(this.apiURL + url, data, this.getHttpOptions());
  }

  getData(url) {
    return this.httpClient.get(this.apiURL + url, this.getHttpOptions());
  }

  getImageData(url) {
    return this.httpClient.get(this.apiURL + url);
  }

  delete(url) {
    return this.httpClient.delete(this.apiURL + url, this.getHttpOptions());
  }

  getApiData(url) {
    return this.httpClient.get(this.apiURL + url, this.getHttpOptions()).toPromise();
  }

  public getServerDataSource(apiName) {
    let userData = localStorage.getItem('jlash-info') ? JSON.parse(localStorage.getItem('jlash-info')) : null;
    let options = {
      endPoint: this.apiURL + apiName + '?_sessionId=' + (userData ? userData['sessionId'] : null),
      dataKey: 'data.rows',
      totalKey: 'data.count',
      reqHeaders: {
        'Content-Type': 'application/json',
        'sessionid': userData ? userData['sessionId'] : '',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
      }
    }
    let datas = new CustomDataSource(this.http, this.router, this.alert, options);
    return datas;
  }

  login(data) {
    return this.httpClient.post(this.apiURL + JLASHCONSTANT.API_LOGIN, data, this.getNormalHttpOptions());
  }

  logout() {
    localStorage.removeItem('jlash-info');
    this.router.navigate(['login']);
  }

  logoutUnauthorizedUser() {
    localStorage.removeItem('jlash-info');
  }

  public forgotPassword(data) {
    return this.httpClient.post(this.apiURL + JLASHCONSTANT.API_FORGET_PASSWORD, data, this.getNormalHttpOptions());
  }

  public isLoggedIn() {
    const userData = localStorage.getItem('jlash-info') ? JSON.parse(localStorage.getItem('jlash-info')) : null;
    if (userData !== null) {

      if(this.isTokenExpired(userData.sessionId)) {
        return false
      } else {
        return true;
      }

    }
    else {
      return false;
    }
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public isTokenExpired(token?: string): boolean {
    if(!token) token = this.getLoggedUserInfo();    
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    console.log("the date of expiry is "+date)
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }


}
