import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { JlashServices } from '../../services/jlashservices.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  pureEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loading: boolean;
  loginForm: FormGroup;
  email: AbstractControl;
  password: AbstractControl;

  constructor(private router: Router, private formBuilder: FormBuilder, private alert: AlertService, private api: JlashServices) {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.pureEmail)])],
      password: ['', Validators.required]
    });

    this.email = this.loginForm.controls['email'];
    this.password = this.loginForm.controls['password'];
  }

  ngOnInit() {
    if (this.api.getLoggedUserInfo)
      this.router.navigate(['pages/dashboard']);
  }

  onLoginButtonClick(data) {
    this.loading = true;
    this.api.login(data).subscribe((response: any) => {
      if (response.status === 0) {
        this.loading = false;
        this.alert.showWarning(response.displayMessage);
      } else {
        localStorage.setItem('jlash-info', JSON.stringify(response.data));
        this.loading = false;
        this.alert.show(response.displayMessage);
        this.api.updateDes('success')
        this.router.navigate(['pages/dashboard'])
      }
    },
      error => {
        this.alert.showWarning('Invalid username or password');
        this.loading = false;
      },
    );
  }

  forgotPassword() {
    this.router.navigate(['forget-password']);
  }



}
