import { environment } from "../../environments/environment";

export class JLASHCONSTANT {
    public static API_LOGIN = 'login';
    public static API_FORGET_PASSWORD = 'forget-password';
    public static API_CHANGE_PASSWORD = 'change-password'
    public static API_STAFF = 'user';
    public static API_LOCATION = 'location';
    public static API_CUSTOMER = 'customer';
    public static API_CUSTOMER_DETAILS = 'customerdetails';
    public static API_CUSTOMER_REPORT = 'customerReport2';
    public static API_APPOINTMENT = 'appointment';
    public static API_APPOINTMENT_LIST = 'appointmentList';
    public static API_APPOINTMENT_IMAGE = 'appointmentImage';
    public static API_DASHBOARD = 'dashboard';
    public static API_CONFIG_SERVICE = 'service';
    public static API_APPOINTMENT_DETAILS = 'viewAppointmentDetail'
    public static API_EDIT_APPOINTMENT_DETAILS = 'editAppointmentDetail'
    public static API_PAYROLL='payroll'
    public static API_STAFF_SERVICE_AMOUNT = 'serviceAmount'
    public static API_STAFF_BREAKDOWN_DETAILS = 'serviceGraph'
    public static API_STAFF_BREAKDOWN_TABLE_DETAILS = 'breakdownGraph'
    public static API_STAFF_REVENUE_CHART_DETAILS = 'revenueTrend'
    public static API_DAILY_LOCATION_REVENUE_CHART_DETAILS = 'locationRevenue'
    public static API_YEARLY_REVENUE_BY_SERVICE = 'revenueService'
    public static API_CUSTOMER_RETENTION_REPORT = 'customerRetentionReport'
    public static API_RECENT_ACTIVITY = 'recent-activities'
    public static API_IS_SERVICE_USED = 'is-service-used'

    public static API_EYE_LASH_IMAGE = "../../../../assets/images/eylash.jpeg";
    public static API_EYE_LASH_IMAGE_2 = 'eylash.jpeg';
}
