import { FormControl, FormGroup } from "@angular/forms";

export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}

export function blockSpecialCharacters(event) {
    var key = window.event ? event.keyCode : event.which;

    let checkStringValues = '!@#$%^&*()<>,?:';
    if (event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 110 || event.keyCode == 9  || event.keyCode == 190) {
      return true;
    } else if (event.keyCode >= 96 && event.keyCode <= 105) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else if (checkStringValues.includes(event.key)) {
      return false;
    } else return true;
  }

export function calculateGstAmount(amount, date) {
  let gstAmount = (parseFloat(amount)*100/100) + ((parseFloat(amount)*100/100) * getGSTPercentageByDate(date))
  return gstAmount.toFixed(2)
}

export function calculateGstAmountWithDiscount(data, date) {
  let gstAmount = (parseFloat(data.price)*100/100) + ((parseFloat(data.price)*100/100) * getGSTPercentageByDate(date))
  gstAmount = gstAmount - data.manualDiscount
  return gstAmount.toFixed(2)
}

export function getGSTPercentageByDate(date=null) {
  const gstEightpercentDate = new Date("2023-01-06");
  const gstNinepercentDate = new Date("2024-01-01");

  const apptDate = date ? new Date(date) : new Date();
  if(apptDate >= gstNinepercentDate) {
      return 9/100
  } else if(apptDate >= gstEightpercentDate && apptDate <= gstNinepercentDate) {
      return 8/100
  } else {
      return 7/100
  }
}