import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import { AlertService, JlashServices } from '../../services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {

  pureEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loading: boolean;
  forgotPasswordForm: FormGroup;
  email: AbstractControl;

  constructor(private router: Router, private api: JlashServices,
    private formBuilder: FormBuilder, private alert: AlertService) {

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.pureEmail)])],
    });

    this.email = this.forgotPasswordForm.controls['email'];
  }

  ngOnInit() { }

  submit(data) {
    this.loading = true;
    this.api.forgotPassword(data).subscribe((res: any) => {
      if (res.status === 0) {
        this.alert.showWarning(res.displayMessage);
        this.loading = false;
      } else {
        this.loading = false;
        this.alert.show(res.displayMessage);
        this.router.navigate(['login']);
      }
    },
      error => {
        this.alert.showWarning(error.error.detail);
        this.loading = false;
      });
  }

  login() {
    this.router.navigate(['login']);
  }
}
