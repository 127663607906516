import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { JlashServices, JLASHCONSTANT, blockSpecialCharacters } from '../../../services'

import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-package-balance',
  templateUrl: './package-balance.component.html',
  styleUrls: ['./package-balance.component.scss']
})
export class PackageBalanceComponent implements OnInit {
  @Input() data: any;
  @Input() paramsId: any;
  @Output() changePackageBalance;

  packageBalanceAmt: any;

  constructor(private apiService: JlashServices, private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  onSubmitButtonClick() {
    this.changePackageBalance(this.packageBalanceAmt);
  }

  onCloseButtonClick() {
    this.bsModalRef.hide();
  }

  blockSpecialCharacters(event) {
    let value = blockSpecialCharacters(event);
    return value;
  }

}
