import { Injectable } from '@angular/core';
@Injectable()
export class DateFormatService {
	constructor() { }

	searchConvertIntoDate(date, time = null) {

		if (!date)
			return null;

		let newDate = null;
		let { year, month, day } = date;
		if (!year) {
			return null;
		}
		newDate = new Date(year, month - 1, day);

		return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
	}

}