import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';


@Injectable()

export class AlertService {

    constructor(private modalService: BsModalService,) { }

    show(message, callback = null) {
        swal(message, null, 'success').then(function () {
            if (callback)
                callback();;
        });
    }

    showWarning(message, callback = null) {
        if(message == '') {
            message = 'This session has expired. Please login again!'
        }
        swal(message, null, 'warning').then(function () {
            if (callback)
                callback();;
        });
    }

    showImage(imageUrl) {
        swal({
            imageUrl: imageUrl,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
            animation: false,
            customClass: 'imagePopup'
        })
    }

    showConfigurationInput(message, details, callback = null) {
        swal({
            title: message,
            html:
            `Name:<input id="swal-input1" class="swal2-input" ${details.isServiceTypeUsed ? 'readonly': ''}>` +
            'Amount:<span class="input-symbol-dollar-config"><input id="swal-input2" class="swal2-input"></span>',
            focusConfirm: false,
            inputValue: "test",
            onOpen: () => {
                document.getElementById('swal-input1')['value'] = details.serviceTypeName;
                document.getElementById('swal-input2')['value'] = details.amount;
            },
        }).then(function (result) {
            if (result.value) {
                let name = document.getElementById('swal-input1')['value'];
                let amount = document.getElementById('swal-input2')['value'];
                result['name'] = name;
                result['amount'] = amount;
                callback(result);
            }
        });
    }

    showInput(message, details, callback = null) {
        swal({
            title: message,
            input: 'text',
            inputValue: details.serviceTypeName,
        }).then(function (result) {
            if (result.value) {
                callback(result);
            }
        });
    }

    confirmation(message, callback, buttonName, cancelCallback?) {
        swal({
            title: message,
            text: null,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: buttonName
        }).then(function (result) {
            if (result.value) {
                callback();
            }else{
                cancelCallback ? cancelCallback() : null
            }
        });
    }

    showHtmlDesign(htmldesign, message) {
        swal({
            title: message,
            html: htmldesign,
            customClass: "appointmentDetails"
        }).then(function (result) { });
    }

    confirmationWithProceeding(callback, mode, meassage, buttonName1, buttonName2) {
        swal({
            title: meassage,
            type: mode,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: buttonName1,
            confirmButtonAriaLabel: buttonName1,
            cancelButtonText: buttonName2,
            cancelButtonAriaLabel: buttonName2,
        }).then(function (result) {
            if (result) {
                callback(result);
            }
        });
    }
    
    showAlert(data, component, className = null) {
        const modalClass = 'setting-modal ' + (className ? className : '');
        const initialState = data;
        this.modalService.show(component, Object.assign({ initialState }, { class: modalClass }));
    }

}