import { ServerDataSource } from "ngx-smart-table";
import { Router } from "@angular/router";
import { Http } from "@angular/http";
import { ServerSourceConf } from "ngx-smart-table/lib/data-source/server/server-source.conf";
import { AlertService } from '../services'
// import { ToasterNotificationService } from './toaster.notification.service';
// import { Toast } from "angular2-toaster";

export class CustomDataSource extends ServerDataSource {
    protected conf: ServerSourceConf;
    constructor(http: Http, private _router: Router, private alert: AlertService, conf?: ServerSourceConf | {}) {
        super(http, conf);
    }
    protected emitOnChanged(action: string): void {
        this.getElements()
            .then((elements) => {
                this.onChangedSource
                    .next({
                        action: action,
                        elements: elements,
                        paging: this.getPaging(),
                        filter: this.getFilter(),
                        sort: this.getSort()
                    });
            })
            .catch((error) => {
                if (error.status && error.status === 401) {
                    localStorage.removeItem('jlash-info');
                    this._router.navigate(['/login']);
                    this.alert.showWarning('', 'Unauthorized User! please login again');
                    return;
                }

                if (error._body) {
                    try {
                        if (JSON.parse(error._body).errorCode === "error-001") {
                            localStorage.removeItem('jlash-info');
                            this._router.navigate(['/login']);
                            this.alert.showWarning('', 'Session Expired! please login again');
                            return;
                        }
                    } catch (error) {
                    }
                }
                this.onChangedSource
                    .next({
                        error: error,
                        action: action,
                        elements: [],
                        paging: this.getPaging(),
                        filter: this.getFilter(),
                        sort: this.getSort()
                    })
            });
    }
} 