import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Ng2SmartTableModule } from 'ngx-smart-table';
import { AppRoutingModule } from './app-routing.module';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AuthGuard } from './auth.guard';
import { JlashServices, AlertService, ErrorInterceptorService, DateFormatService } from './services';

import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { DrawingComponent } from './component/drawing/drawing.component';
import { SignaturePadComponent } from './component/signature-pad/signature-pad.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { PackageBalanceComponent } from './component/pages/package-balance/package-balance.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    PackageBalanceComponent,
   // SignaturePadComponent
  ],
  entryComponents: [
    PackageBalanceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SmartTableModule,
    CanvasWhiteboardModule,
    ModalModule.forRoot(),
  ],
  providers: [JlashServices, AlertService, AuthGuard, DatePipe, DateFormatService,BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
