
import {throwError as observableThrowError,  Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';




import { AlertService } from './alert.service';
import { JlashServices } from './jlashservices.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(private injector: Injector, private alert: AlertService, private api: JlashServices,
        private router: Router) { }

    //Checks for service error and throws generic toastr message through out the application
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .do((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                    if (ev.ok && ev.body && ev.body['message']) { }
                }
            })
            .catch((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 401) {
                        // this.alert.show('This session has been expired. Please login again!')
                        // localStorage.removeItem('jlash-info');
                        // this.router.navigate(['login']);
                        this.api.logoutUnauthorizedUser();
                    }
                    
                }
                return observableThrowError(response);
            });
    }
}